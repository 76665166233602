import React from "react"
import Button from "../../../components/Button";
import { Link } from "gatsby";
import Layout from "../../../components/layout"
import Seo from "../../../components/Seo"
import {
  PrimaryHero,
  HeroCopyBlock
} from "../../../components/Heros"
import OurClients from "../../../components/OurClients"
import OurCarriers from "../../../components/OurCarriers"
import {
  DualImageBlock,
  RightDualComponent
} from "../../../components/Grid"
import RequestDemo from "../../../components/RequestDemo"

import cx from "classnames"
import styles from "./styles.module.scss"

import messageIcon from "./images/default.svg"
import tickPurple from "./images/tickPurple.svg"
import tickWhite from "./images/tickWhite.svg"
import vanImg from "./images/van.png"
import cityImg from "./images/city.png"
import lushImg from "./images/lush.svg"
import lushBg from "./images/lushbg.png"
import quoteImg from "./images/quote.svg"
import proImg from "./images/pro.png"
import codeImg from "./images/code.png"
import conveyorImg from "./images/conveyor.png"
import problemImg from "./images/problem.png"

const ProImages = () => (
  <div className={styles.proImages}>
    <img src={codeImg} alt="code on a screen" />
    <img src={problemImg} alt="people solving a problem" />
    <img src={conveyorImg} alt="conveyor belt" />
  </div>
)

const Pro = () => (
  <Layout>
    <Seo title="Sorted | Products - Pro" keywords={[`Sorted`, `Products`, `Pro`]} />
    <PrimaryHero theme="pink">
      <HeroCopyBlock title={["Management for", "the modern world."]}>
        <p>The reliable DC operations dashboard, with scalable, single-integration carrier cloud, consignment allocation and label printing. Liberating you from IT constraints, making your logistics easier and more efficient.</p>
      </HeroCopyBlock>
    </PrimaryHero>
    <div className={cx("container", styles.clientImages)}>
      <OurClients />
    </div>

    <div className="bg-light">
      <div className="container">
        <div className={styles.productPoints}>
          <div className={styles.productPoint}>
            <img src={messageIcon} alt=""/>
            <div>
              <p>Blue-green deployment.</p>
            </div>
          </div>
          <div className={styles.productPoint}>
            <img src={messageIcon} alt=""/>
            <div>
              <p>Automatic rule-based carrier allocation.</p>
            </div>
          </div>
          <div className={styles.productPoint}>
            <img src={messageIcon} alt=""/>
            <div>
              <p>Self service rates management.</p>
            </div>
          </div>
          <div className={styles.productPoint}>
            <img src={messageIcon} alt=""/>
            <div>
              <p>3000+ global carrier service library.</p>
            </div>
          </div>
          <div className={styles.productPoint}>
            <img src={messageIcon} alt=""/>
            <div>
              <p>Global customs documents</p>
            </div>
          </div>
          <div className={styles.productPoint}>
            <img src={messageIcon} alt=""/>
            <div>
              <p>Carrier compatible shipping labels</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container-thin">
      <div className={styles.statementBlock}>
        <div className={styles.statementBlockBrand}>
          <img className={styles.statementBlockBrandBg} src={lushBg} alt="lush logo"/>
          <div className={styles.statementBlockBrandImg}>
            <img src={lushImg} alt="lush logo"/>
          </div>
        </div>
        <div className={styles.statementBlockQuote}>
          <div>
            <img src={quoteImg} alt="quote marks"/>
            <p><b>Lorem ipsum dolor sit amet</b></p>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore dolore magna aliquyam erat, sed diam voluptua.</p>
          </div>
        </div>
      </div>
    </div>

    <div className={cx("bg-light", styles.carrierSection)}>
      <div className="container">
        <h3 className="text-center">Carrier's best friend</h3>
      </div>
      <div className="container-ultrathin">
        <p className="text-center">With no monolithic legacy carrier library to maintain, we connect retailers to the most in-demand global carriers in the market.</p>
      </div>
      <div className="container">
        <div className={styles.productTriHolder}>
          <div className={cx(styles.productTriItem, styles.productTriItemWhite)}>
            <div>
              <img src={tickPurple} className={styles.productTriItemTick} alt="" />
              <p><b>Tailored to you</b></p>
              <p>Niche carrier? No problem. We can work with you to integrate any carriers we might not already have.</p>
            </div>
          </div>
          <div className={cx(styles.productTriItem, styles.productTriItemImg)}>
            <img src={vanImg} alt="van"/>
            <div>
              <img src={tickWhite} className={styles.productTriItemTick} alt="" />
              <p><b>Ready for the world</b></p>
              <p>No ambiguity or complex set-up for multi-carrier labels or customs docs.</p>
            </div>
          </div>
          <div className={cx(styles.productTriItem, styles.productTriItemPink)}>
            <div>
              <img src={tickWhite} className={styles.productTriItemTick} alt="" />
              <p><b>Ease your budgets and costings</b></p>
              <p>Carrier costs are translated, layered and simplified - so lose the calculator and complex spreadsheets.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className={styles.ourCarriersHolder}>
          <OurCarriers />
          <div className={styles.carrierButton}>
            <Button theme="greyoutline"><Link to="/carriers">Learn more about carriers</Link></Button>
          </div>
        </div>
      </div>
    </div>

    <div className={styles.cityBlock}>
      <DualImageBlock reverse img={cityImg} theme="purple" maxHeight={700}>
        <div>
          <h3>Scalability, flexibility, agility.</h3>
          <p>Delivery offering and customer experience should never be restricted by your warehouse operational capability. Get growing, without restrictions from your software.</p>
          <div className={styles.tickPoints}>
            <div className={styles.tickPoint}>
              <img src={tickWhite} alt="white tick"/>
              <div>
                <p><b>One-click carrier management</b></p>
                <p>Adding or changing carrier services doesn’t have to be your responsibility, or expensive or slow.</p>
              </div>
            </div>
            <div className={styles.tickPoint}>
              <img src={tickWhite} alt="white tick"/>
              <div>
                <p><b>Tried and tested</b></p>
                <p>Peak proven, reliable and solid under volume fluctuations.</p>
              </div>
            </div>
            <div className={styles.tickPoint}>
              <img src={tickWhite} alt="white tick"/>
              <div>
                <p><b>Handle your own change</b></p>
                <p>We don’t want you to work our way, we work to yours.</p>
              </div>
            </div>
          </div>
          <Button theme="white"><a href="#">Learn more</a></Button>
        </div>
      </DualImageBlock>
    </div>

    <div className="container-thin">
      <div className={styles.statementBlock}>
        <div className={styles.statementBlockBrand}>
          <img className={styles.statementBlockBrandBg} src={lushBg} alt="lush logo"/>
          <div className={styles.statementBlockBrandImg}>
            <img src={lushImg} alt="lush logo"/>
          </div>
        </div>
        <div className={styles.statementBlockQuote}>
          <div>
            <img src={quoteImg} alt="quote marks"/>
            <p><b>Lorem ipsum dolor sit amet</b></p>
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore dolore magna aliquyam erat, sed diam voluptua.</p>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div className={styles.computerBlock}>
        <div className={styles.computerBlockOverlay} />
        <div className={cx("container", styles.computerBlockTop)}>
          <div className={styles.computerBlockTicks}>
            <div className={styles.tickPoint}>
              <img src={tickPurple} alt="white tick"/>
              <div>
                <p><b>Light-touch, but robust</b></p>
                <p>No heavy maintenance for shipments, with rules-based automated consignment allocation.</p>
              </div>
            </div>
            <div className={styles.tickPoint}>
              <img src={tickPurple} alt="white tick"/>
              <div>
                <p><b>Beautiful, intuitive UI</b></p>
                <p>Putting your world in one place – from state management to exceptions and reporting.</p>
              </div>
            </div>
          </div>
          <img className={styles.computerBlockImage} src={proImg} alt="pro dashboard" />
        </div>
        <div className={styles.computerBlockFooter}>
          <div className="container">
            <div className={styles.computerBlockFooterInner}>
              <div>
                <h3>One-stop management dashboard.</h3>
                <p>Put your warehouse world in one place, with mobile-ready management off all operations and admin.</p>
              </div>
              <div>
                <Button theme="white"><a href="#">Learn more</a></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className={styles.proImagesPad}>
      <div className="container-large-fluid">
        <RightDualComponent
          rightComponent={<ProImages />}
          theme="light"
        >
          <div className={styles.magiComponent}>
            <h3>Ease of integration.</h3>
            <p>Pure SaaS, with lightweight installation and full support from our expert techies.</p>
            <div className={styles.tickPoint}>
              <img src={tickPurple} alt="white tick"/>
              <div>
                <p><b>Light-touch, but robust</b></p>
                <p>No heavy maintenance for shipments, with rules-based automated consignment allocation.</p>
              </div>
            </div>
            <div className={styles.tickPoint}>
              <img src={tickPurple} alt="white tick"/>
              <div>
                <p><b>Light-touch, but robust</b></p>
                <p>No heavy maintenance for shipments, with rules-based automated consignment allocation.</p>
              </div>
            </div>
            <div className={styles.tickPoint}>
              <img src={tickPurple} alt="white tick"/>
              <div>
                <p><b>Light-touch, but robust</b></p>
                <p>No heavy maintenance for shipments, with rules-based automated consignment allocation.</p>
              </div>
            </div>
            <div>
              <Button theme="purple"><a href="#">Learn more</a></Button>
            </div>
          </div>
        </RightDualComponent>
      </div>
    </div>

    <RequestDemo />

  </Layout>
)

export default Pro
